<template>
  <v-card>
    <v-container>
      <h3> หน้าแสดงข้อมูล ภ.ด.ส.3 </h3>
      <v-row>
        <v-col cols="4" class="mr-4">
          <v-text-field
            v-model="search"
            dense
            hide-details
            outlined
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :search="search"
        :items-per-page="10"
        class="elevation-1 mt-4"
        :loading="loading"
        loading-text="กำลังโหลดข้อมูล...กรุณารอสักครู่"
      >
        <template v-slot:[`item.count`]="{ item }">
          {{ item.count }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-row justify="center">
            <!-- <v-icon @click="deleteData(item)">mdi-delete</v-icon> -->
            <v-icon @click="viewpds3(item)">mdi-magnify</v-icon>
            <v-icon @click="deletepds3(item)">mdi-delete</v-icon>
          </v-row>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>
<script>
import axios from "axios";
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      search: "",
      loading: true,
      headers: [
        { text: "ที่", value: "count", align: "center" },
        { text: "ชื่อ - สกุล", value: "fullName", align: "center" },
        { text: "เลขบัตรประจำตัวประชาชน", value: "idCardNo", align: "center" },
        { text: "ที่อยู่", value: "address", align: "center" },
        { text: "เลขที่เอกสารสิทธิ์", value: "documentNo", align: "center" },
        { text: "การจัดการ", value: "action", align: "center" },
      ],
      list: [],
      count: 0,
      checkuser: [],
    };
  },
  created() {
    this.checkuser = JSON.parse(Decode.decode(localStorage.getItem("user")));
    this.getpds3();
  },
  methods: {
    deletepds3(item) {
      const id = item.id;
      console.log("id", id);
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const auth = {
            headers: { Authorization: `Bearer` + " " + this.checkuser.token },
          };

          await axios.delete(`${process.env.VUE_APP_API}/pds3s/` + id, auth);
          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          await this.getpds3();
        }
      });
    },
    async viewpds3(val) {
      console.log("val", val);
      // val.id = JSON.parse(Decode.decode(localStorage.setItem("id")));
      localStorage.setItem("data", Encode.encode(val));
      this.$router.push("/viewallpds3");
    },
    async getpds3() {
      const auth = {
        headers: { Authorization: `Bearer` + " " + this.checkuser.token },
      };

      // console.log(token);
      const response = await axios.get(
        `${process.env.VUE_APP_API}/pds3s`,
        auth
      );
      console.log("ดาต้าที่ออกมา", response.data.data);
      this.list = response.data.data;
      for (let i in this.list) {
        this.list[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.f-right {
  float: right;
}
</style>
